<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增任务</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="taskList" stripe style="width: 100%" height="100%">
        <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="mainHeadName" label="主要承办人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="headName" label="协办人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-show="scope.row.status===0">未完结</el-tag>
            <el-tag type="success" size="small" v-show="scope.row.status===1">已完结</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.startTime|timeFormat}} 至 {{scope.row.endTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip content="修改" placement="top" effect="dark">
              <el-button type="warning" circle icon="el-icon-edit" size="small" @click="open('edit',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" effect="dark">
              <el-button type="danger" circle icon="el-icon-delete" size="small" @click="delTask(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip content="检查" placement="top" effect="dark">
              <el-button type="primary" circle icon="el-icon-view" size="small" @click="open('check',scope.row.id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="optFlag?'新增任务':'修改任务'" :visible.sync="taskShow" width="35%" top="3%" v-dialogDrag @close="clear">
      <el-form :model="task" ref="taskForm" :rules="rules" label-width="80px">
        <el-form-item label="检查单位" prop="unitId">
          <el-select v-model="task.unitId" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" style="float:left">
            <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
              <span style="float: left">{{ item.unitName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="承办人" prop="mainHead">
          <el-select v-model="task.mainHead" placeholder="请选择承办人" clearable filterable style="float:left">
            <el-option v-for="(item,i) in userList" :key="i" :label="item.realname" :value="item.id">
              <span style="float: left">{{ item.realname }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.username }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协办人" prop="head">
          <el-select v-model="task.head" placeholder="协办人" clearable filterable style="float:left">
            <el-option v-for="(item,i) in userList" :key="i" :label="item.realname" :value="item.id">
              <span style="float: left">{{ item.realname }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.username }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="task.status" value-key="" placeholder="请选择状态" clearable style="float:left">
            <el-option v-for="(item,i) in [{id:0,label:'未完结'},{id:1,label:'已完结'}]" :key="i" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker v-model="time" type="datetimerange" start-placeholder="开始日期" end-placeholder="截止日期" style="float:left">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="taskShow = false">取 消</el-button>
        <el-button type="primary" @click="addTask" v-show="optFlag">新增任务</el-button>
        <el-button type="primary" @click="updateTask" v-show="!optFlag">修改任务</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    const validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择协办人'))
      } else if (value === this.task.mainHead) {
        callback(new Error('承办人和协办人不能为同一个人!'))
      } else {
        callback()
      }
    }
    return {
      currentPage: 1,
      total: 0,
      optFlag: true,
      contentShow: false,
      taskShow: false,
      taskList: [],
      taskId: 0,
      task: {},
      time: '',
      taskFormCopy: {},
      searchForm: {},
      unitOptions: [],
      userList: [],
      isClear: false,
      ogranizedId: 0,
      rules: {
        head: [
          { validator: validatePass1 }
        ]
      }
    }
  },
  created () {
    this.taskFormCopy = JSON.stringify(this.task)
    this.ogranizedId = sessionStorage.getItem('ogranizedId')
    this.initTable()
    this.getUser()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/supervisionInspect/list', { params: data })
      if (result.code === 200) {
        this.taskList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.taskShow = true
          this.$nextTick(() => {
            this.$refs.taskForm.clearValidate()
          })
          break
        case 'edit':
          this.optFlag = false
          this.taskShow = true
          this.$nextTick(() => {
            this.$refs.taskForm.clearValidate()
          })
          this.task = JSON.parse(JSON.stringify(row))
          this.taskId = row.id
          break
        case 'check':
          this.$router.push(`/home/taskinfo/${row}`)
          break
      }
    },
    // 添加任务
    addTask () {
      this.$refs.taskForm.validate(async valid => {
        if (valid) {
          this.task.startTime = this.time[0]
          this.task.endTime = this.time[0]
          const { data: result } = await this.$axios.post('/supervisionInspect/add', this.task)
          if (result.code === 200) {
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.$message.success('添加成功')
            this.taskShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除任务
    async delTask (id) {
      const confirm = await this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/supervisionInspect/del/${id}`)
        if (result.code === 200) {
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改任务
    async updateTask () {
      this.$refs.taskForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/supervisionInspect/edit/${this.taskId}`, this.task)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.taskShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async getUser () {
      const { data: result } = await this.$axios.get('/user/list', { params: { ogranizedId: this.ogranizedId } })
      if (result.code === 200) {
        this.userList = result.data
      }
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    clear () {
      this.task = JSON.parse(this.taskFormCopy)
      this.$refs.taskForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
